.dashboard__main {
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  max-width: 70%;
  flex-grow: 1;
}

@media screen and (max-width: 1300px) {
  .dashboard__main {
    padding: 15px;
  }
}

@media screen and (max-width: 1150px) {
  .dashboard__main {
    padding: 10px;
  }
}

@media screen and (max-width: 900px) {
  .dashboard__main {
    padding: 7.5px;
  }
}

@media screen and (max-width: 600px) {
  .dashboard__main {
    padding: 3px;
  }
}
