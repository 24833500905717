.detailed-post__statistic__item {
  display: flex;
  text-align: center;
  height: 40px;
  margin: 0;
  padding: 0;
  border-bottom: 2px solid rgba(248, 221, 230, 1);
}

.detailed-post__statistic__item:last-of-type {
  border: none;
}
