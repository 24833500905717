.main__connected-sm-list__item {
  max-height: 160px;
  min-height: 90px;
  height: 100%;
  aspect-ratio: 5/5.3;
  background: rgba(255, 252, 238, 1);
  border-radius: 30px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
