.input {
  margin: 0 20px 20px;
  border: none;
  border-bottom: 1.5px solid rgba(150, 150, 150, 1);
  background-color: rgba(0, 0, 0, 0);
  text-align: center;
  color: rgba(150, 150, 150, 1);
}

.input:focus {
  outline: none;
}
