.dashboard-settings__menu {
  margin: 0;
  padding: 0 20px;
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 4px solid rgba(248, 221, 230, 1);
  border-top: none;
  border-bottom: none;
}
