.connected-sm-list__item__followers {
  margin: 20px 0 0;
  padding: 0;
  text-align: center;
  flex: 100%;
  align-content: flex-end;
}

@media screen and (max-width: 1800px) {
  .connected-sm-list__item__followers {
    margin: 15px 0 0;
    font-size: 17px;
  }
}

@media screen and (max-width: 1800px) {
  .connected-sm-list__item__followers {
    font-size: 14px;
  }
}
