.post-feed {
  position: relative;
  margin: 20px auto;
  padding: 10px 0;
  max-width: 95%;
  width: 100%;
  min-width: 300px;
  flex-grow: 1;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}
