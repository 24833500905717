.detailed-post__statistic__text {
  margin: auto;
  width: 50%;
  /* height: 30px; */
  text-align: center;
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
