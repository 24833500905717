.auth__form {
  margin: 50px 30px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;

  max-width: 415px;
  max-height: 495px;
  height: 100%;
  width: 100%;

  border-radius: 40px;
  border: 1.8px;

  background: linear-gradient(
      169.68deg,
      #ffffff 6.36%,
      rgba(255, 255, 255, 0) 78.22%
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(196, 196, 196, 0) 156.08%
    );

  border-image-source: linear-gradient(
    169.68deg,
    #ffffff 6.36%,
    rgba(255, 255, 255, 0) 78.22%
  );
}
