.main__connected-sm-list {
  margin: 0 0 15px;
  padding: 0;
  width: 100%;
  height: 20%;
  min-height: 90px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
